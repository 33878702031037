import { rgba } from 'polished'
import { createGlobalStyle } from 'styled-components'

import { theme } from './theme'


const GlobalStyle = createGlobalStyle`
  body,
  html {
    min-width: 320px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }
  html {
    /* overflow-x: hidden; */
    /* margin: 0 auto; */
    height: 100%;
    height: -webkit-fill-available;
    /* background: blue; */
  }
  /* Set core body defaults */
  body {
    padding: 0;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    display: flex;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  #__next {
    width: 100%;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .overscroll-behavior-none {
    overscroll-behavior: none;
  }
    /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  /* Remove default padding */
  ul[class],
  ol[class] {
    padding: 0;
  }
  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }
  /* Remove list styles on ul, ol elements with a class attribute */
  ul[class],
  ol[class] {
    list-style: none;
  }
  /* A elements that don't have a class get default styles */
  a {
    color: inherit;
    text-decoration: none;
  }
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  /* img {
    max-width: 100%;
    display: block;
  } */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  body #nprogress .bar {
    background: white;
  }

  body #nprogress .spinner-icon {
    border-top-color: white;
    border-left-color: white;
  }

    /** Used to define container behavior: width, position: fixed etc... **/
  .Toastify__toast-container {
    padding: 0;
    width: max(270px, 18.750000vw);
  }

  .Toastify__toast-container .Toastify__toast:not(:last-child) {
    margin-bottom: max(10px, 0.694444vw);
  }

  /** Used to define the position of the ToastContainer **/
  .Toastify__toast-container--top-left {
  }
  .Toastify__toast-container--top-center {
  }
  .Toastify__toast-container--top-right {
  }
  .Toastify__toast-container--bottom-left {
    left: 30px;
    bottom: 30px;
  }
  .Toastify__toast-container--bottom-center {
  }
  .Toastify__toast-container--bottom-right {
  }

  /** Classes for the displayed toast **/
  .Toastify__toast {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: max(25px, 1.736111vw);
    margin: 0;
    min-height: unset;
    padding: max(21px, 1.458333vw) max(24px, 1.666667vw);
  }
  .Toastify__toast--rtl {
  }
  .Toastify__toast--dark {
  }
  .Toastify__toast--default {
  }
  .Toastify__toast--info {
  }
  .Toastify__toast--success {
    text-decoration: underline;
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--error {
    background-color: ${rgba(theme.colors.error, 0.85)};
  }
  .Toastify__toast-body {
    font-family: ${theme.fonts.fontFamilySecondary};
    font-size: max(16px, 1.111111vw);
    color: ${theme.colors.white};
    font-weight: normal;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;

    &:before {
      content: '';
      display: block;
      height: max(24px, 1.666667vw);
      width: max(24px, 1.666667vw);
      margin-right: max(10px, 0.694444vw);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.70711 14.2929L19 5L20.4142 6.41421L9.70711 17.1213L4 11.4142L5.41421 10L9.70711 14.2929Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }

  /** Classes for the close button. Better use your own closeButton **/
  .Toastify__close-button {
  }
  .Toastify__close-button--default {
  }
  .Toastify__close-button > svg {
  }
  .Toastify__close-button:hover, .Toastify__close-button:focus {
  }

  /** Classes for the progress bar **/
  .Toastify__progress-bar {
  }
  .Toastify__progress-bar--animated {
  }
  .Toastify__progress-bar--controlled {
  }
  .Toastify__progress-bar--rtl {
  }
  .Toastify__progress-bar--default {
  }
  .Toastify__progress-bar--dark {
  }
`

export { GlobalStyle }
