import React from 'react'

import { SVG } from '../../types/svg'

// width="45"
// height="45"
export const X: React.FC<SVG> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
      {...props}
    >
      <rect width="45" height="45" fill="#fff" rx="22.5"></rect>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M22 20.586l6.363-6.364 1.415 1.414L23.414 22l6.364 6.364-1.415 1.414L22 23.414l-6.364 6.364-1.415-1.414L20.585 22l-6.364-6.364 1.415-1.414L22 20.586z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

