import React, { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'

import Button, { IconContainer } from './Button'
import { mediaMin } from '../../../constants/breakpoints'

type ButtonLabelProps = { className?: string, children : any, text? : string }

export const ButtonLabel: React.FC<ButtonLabelProps> = ({ children, className, text }: ButtonLabelProps) => {
  const useChildren = React.Children. count(children) > 0
  return (
    <div className={`button-label ${className}`}>
      <div className={`button-label-content ${(useChildren || text === '') ? 'notext' : ''}`}>
        {useChildren ? (
          children
        ) : (
          <span className="button-label-text">{text}</span>
        )}
      </div>
    </div>
  )
}

// background: ${({ theme }) => rgba(`${theme.colors.white}`, 0.15)};
const StyledButtonLabel = styled(ButtonLabel)`
  position: absolute;
  z-index: 1;
  overflow: hidden;
  pointer-events: none;
  height: 100%;
  left: max(22.5px, 1.5625vw);

  .button-label-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    height: 100%;

    transform: translate3d(-100%, 0, 0);
    transition: transform 0.45s cubic-bezier(0.65, 0, 0.076, 1);

    border-radius: 0 1.73077em 1.73077em 0;
    padding: 0 max(22.5px, 1.5625vw);

    &.notext {
      background: transparent;
    }
  }

  .button-label-text {
    z-index: 1;
    opacity: 0;
    font-weight: bold;
    letter-spacing: 0;
    text-transform: uppercase;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: opacity 0.2s cubic-bezier(0.65, 0, 0.076, 1) translateZ(0);
  }
`

const StyledButtonHover = styled(Button)`
  position: relative;
  display: block;
  min-width: 0;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;

  height: 3.125vw;
  min-height: 45px;
  width: 3.125vw;
  min-width: 45px;

  transition: opacity 0.45s cubic-bezier(0.65, 0, 0.076, 1);

  @media ${mediaMin.tablet} {
    &:hover,
    &.active {
      .button-label {
        pointer-events: auto;
      }

      .button-label-content {
        transform: translate3d(0, 0, 0);
        transition: transform 0.45s cubic-bezier(0.65, 0, 0.076, 1);
      }

      .button-label-text {
        opacity: 1;
        transform: translateZ(0);
        transition: opacity 0.45s cubic-bezier(0.65, 0, 0.076, 1) 0.2s;
      }
    }
  }
`

type ThisIsNotHowYouDoIt = HTMLButtonElement & HTMLAnchorElement
export interface ButtonHoverProps {
  children?: ReactNode
  style?: CSSProperties
  className?: string
  type?: 'submit' | 'reset' | 'button'
  onClick?: (event: React.MouseEvent) => void
  icon: ReactNode
  as?: 'a' | 'button'
  active?: boolean
  id?: string
  text?: string
}
const ButtonHover = React.forwardRef<ThisIsNotHowYouDoIt, ButtonHoverProps>(({
  style,
  className,
  type,
  onClick,
  active,
  icon,
  id,
  text,
  children,
  as,
}, ref) => {
  const finalClassName = `${className} ${active ? 'active' : ''}`

  return (
    <StyledButtonHover
      ref={ref}
      as={as}
      style={style}
      className={finalClassName}
      type={type}
      onClick={onClick}
      id={id}
    >
      <span className="sr-only">{text || 'Language change button'}</span>
      <IconContainer>{icon}</IconContainer>

      <StyledButtonLabel text={text}>{children}</StyledButtonLabel>
    </StyledButtonHover>
  )
})

ButtonHover.displayName = 'ButtonHover'

export default ButtonHover
