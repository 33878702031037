import styled, { keyframes } from 'styled-components'

interface LiveIndicatorProps {
  redDotPercentage?: string
}

const pulse = keyframes`
  ${'0%'} {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  ${'70%'} {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  ${'100%'} {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
`

export const LiveIndicator = styled.div<LiveIndicatorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  background: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: 0;

  &:before {
    content: '';
    display: block;
    width: ${({ redDotPercentage }) => redDotPercentage ? redDotPercentage : '22%'};
    height: ${({ redDotPercentage }) => redDotPercentage ? redDotPercentage : '22%'};
    background: rgba(255, 82, 82, 1);
    border-radius: 50%;
    transform: scale(1);
    animation: ${pulse} 2s infinite;
  }
`
