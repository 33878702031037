/* eslint-disable max-len */
import React from 'react'
import { SVG } from '../../../types/svg'

// width="45"
// height="45"

export const UK: React.FC<SVG> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
      {...props}
    >
      <rect width="45" height="45" fill="#fff" rx="22.5"></rect>
      <mask
        id="mask-uk"
        width="41"
        height="41"
        x="2"
        y="2"
        maskUnits="userSpaceOnUse"
      >
        <rect width="41" height="41" x="2" y="2" fill="#fff" rx="20.5"></rect>
      </mask>
      <g mask="url(#mask-uk)">
        <path fill="#012169" d="M-6.188 2h56.376v41H-6.188V2z"></path>
        <path
          fill="#fff"
          d="M.419 2l21.493 15.46L43.317 2h6.87v5.296l-21.14 15.29 21.14 15.204V43h-7.046L22 27.71.947 43h-7.135v-5.125l21.053-15.204L-6.188 7.467V2H.42z"
        ></path>
        <path
          fill="#C8102E"
          d="M31.16 26.002l19.027 13.581V43l-23.87-16.998h4.844zM14.954 27.71l.529 2.99L-1.431 43h-4.756l21.14-15.29zM50.187 2v.256L28.255 18.315l.176-3.759L45.783 2h4.404zM-6.187 2l21.052 15.033H9.58L-6.188 5.587V2z"
        ></path>
        <path
          fill="#fff"
          d="M15.041 2v41h14.094V2H15.041zM-6.188 15.667v13.666h56.376V15.667H-6.188z"
        ></path>
        <path
          fill="#C8102E"
          d="M-6.188 18.485v8.2h56.376v-8.2H-6.188zM17.86 2v41h8.456V2H17.86z"
        ></path>
      </g>
    </svg>
  )
}
