import styled from 'styled-components'


export interface ExpandButtonProps {
  style?: React.CSSProperties
  className?: string
  order: number
  onClick?: (event: React.MouseEvent) => void
  title?: string
  icon: React.ReactNode
}
export const ExpandButton: React.FC<ExpandButtonProps> = ({
  style,
  className,
  order,
  onClick,
  title,
  icon,
}) => (
  <StyledExpandButton 
    className={className} 
    style={style}
    order={order} 
    onClick={onClick}
    title={title}
  >
    {icon}
  </StyledExpandButton>
)


const createTranslate3d = (order: number) => {
  const percentage = 100 * order
  const paddingOffset = 10 * order
  return `translate3d(calc(-${percentage}% - ${paddingOffset}px),0,0)`
}
interface StyledExpandButtonProps {
  order: number
}
const StyledExpandButton = styled.div<StyledExpandButtonProps>`
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  height: max(45px, 3.125vw);
  width: max(45px, 3.125vw);
  background: ${({theme}) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 10px;

  transition: transform 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transform: ${({ order }) => createTranslate3d(order)};
`