import styled from 'styled-components'

const DottedLoading = styled.div`
  width: 1.9444vw;
  height: 1.9444vw;
  border-radius: 50%;
  position: relative;
  animation: dottedLoader 0.9s ease alternate infinite;
  animation-delay: 0.36s;
  top: 50%;
  margin: -2.9167vw auto 0 auto;
  &:before {
    left: -2vw;
    animation-delay: 0.18s;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 1.9444vw;
    height: 1.9444vw;
    border-radius: 50%;
    animation: dottedLoader 0.9s ease alternate infinite;
  }
  &:after {
    right: max(-40px, -2.778vw);
    animation-delay: 0.54s;
  }
  @keyframes dottedLoader {
    0% {
      box-shadow: 0 1.9444vw 0 -1.9444vw ${({ theme }) => theme.colors.white};
    }
    100% {
      box-shadow: 0 1.9444vw 0 ${({ theme }) => theme.colors.white};
    }
  }
`

export default DottedLoading
