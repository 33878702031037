import React from 'react'

import { SVG } from '../../types/svg'

// width="45"
// height="45"
export const Back: React.FC<SVG> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
      {...props}
    >
      <rect width="45" height="45" fill="#fff" rx="22.5"></rect>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M19.414 23l7.293 7.293-1.414 1.414L16.586 23l8.707-8.707 1.414 1.414L19.414 23z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}