import { useMemo } from 'react'
import { ButtonLive, ButtonLiveItem } from '../../ButtonLive/ButtonLive'
import { Event } from '../../../types/viewModels'
import config from '../../../../config'

interface ButtonLiveEvents {
  className?: string
  style?: React.CSSProperties
  events: Event[]
}
export const ButtonLiveEvents: React.FC<ButtonLiveEvents> = ({ events, ...rest }) => {
  const liveList: ButtonLiveItem[] = useMemo(() => {
    return events.map((event) => {
      const image = event.speakers.length > 0 && event.speakers[0].image[0]
        ? `${config.LISA_IMAGE_BASE} + ${event.speakers[0].image[0]}`
        : undefined
      return {
        id: event.id,
        time: event.timeInterval,
        title: event.titleSimple,
        location: event.location.displayName,
        href: `${config.BASIC_ROUTES.live}/${event.location.slugurl}`,
        imageSrc: image,
        imageFallbackSrc: config.FALLBACK_PERSON_IMAGE,
      }
    })
  }, [events])

  return <ButtonLive liveList={liveList} {...rest} />
}