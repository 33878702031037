/* eslint-disable max-len */
import React from 'react'
import { SVG } from '../../types/svg'

// width="45"
// height="45"
export const Language = (props: SVG) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
      {...props}
    >
      <rect width="45" height="45" fill="#fff" rx="22.5"></rect>
      <path
        fill="#303030"
        fillRule="evenodd"
        d="M22 11c6.075 0 11 4.925 11 11s-4.925 11-11 11-11-4.925-11-11 4.925-11 11-11zm-8.934 9.907c.291.373 1.102.851 2.342 1.24.489.154 1.024.289 1.598.403A27.12 27.12 0 0117 22c0-3.328.606-6.333 1.654-8.358a9.01 9.01 0 00-5.588 7.265zm4.063 3.704c-1.606-.28-2.983-.702-4.026-1.245a9.012 9.012 0 005.552 6.992c-.766-1.479-1.296-3.48-1.526-5.747zm2.05.266c.897.081 1.842.123 2.821.123.963 0 1.912-.055 2.825-.156C24.375 28.442 23.127 31 22 31c-1.123 0-2.366-2.543-2.821-6.123zm5.807-2.072c-.95.122-1.958.195-2.986.195a28.82 28.82 0 01-2.985-.15c-.01-.28-.015-.563-.015-.85 0-5.016 1.594-9 3-9s3 3.984 3 9c0 .271-.005.54-.014.805zm1.893 1.722c-.225 2.302-.758 4.334-1.534 5.83a9.01 9.01 0 005.576-7.16c-1.06.55-2.456 1.012-4.042 1.33zm4.05-3.667c-.471.528-1.955 1.156-3.933 1.594.003-.15.004-.302.004-.454 0-3.328-.606-6.333-1.655-8.358a9.01 9.01 0 015.584 7.218z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
