/* eslint-disable @next/next/no-img-element */
import styled from 'styled-components'
import Link from 'next/link'
import Image from 'next/image'

export interface ButtonLiveItemProps {
  className?: string
  style?: React.CSSProperties
  time: string
  title: string
  location?: string
  href: string
  imageSrc?: string
  imageFallbackSrc: string
}
export const ButtonLiveItem: React.FC<ButtonLiveItemProps> = ({
  className,
  style,
  time,
  title,
  location,
  imageSrc,
  imageFallbackSrc,
  href,
}) => {
  return (
    <Link href={href} passHref>
      <StyledLiveItem className={className} style={style}>
        <div className="live-item">
          <div className="container-image">
            {imageSrc ? (
              <Image 
                src={imageSrc}
                alt="live event thumbnail"
                height={380}
                width={600}
                objectFit="contain"
                objectPosition="center"
              />
            ) : (
              <img className="image" alt="live event thumbnail" src={imageFallbackSrc} />
            )}
          </div>
          <div className="live-information">
            <div title={time} className="live-time">{time}</div>

            <div title={title} className="live-title">{title}</div>

            <div title={location} className="live-location">{location}</div>
          </div>
        </div>
      </StyledLiveItem>
    </Link>
  )
}

const StyledLiveItem = styled.a`
  position: relative;
  z-index: 10;
  padding-bottom: max(10px, 0.694444vw);
  cursor: pointer;
  display: block;
  text-decoration: none;

  .live-item {
    display: flex;
  }

  .container-image {
    position: relative;
    width: max(150px, 10.416668vw);
    height: max(95px, 6.597222vw);
    background-color: #C4C4C4;
    border-radius: max(5px, 0.347222vw);
    margin-right: max(20px, 1.3888888vw);
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    transition-property: opacity;
    transition-timing-function: cubic-bezier(.19,1,.22,1);
    transition-duration: 300ms;

    img.image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .live-information {
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
  }

  .live-time {
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    color: ${({ theme }) => theme.colors.black};
    font-style: normal;
    font-weight: normal;
    font-size: max(14px, 0.9722222vw);
    margin-bottom: max(7px, 0.4861111vw);
  }


  .live-title, .live-location {
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    color: ${({ theme }) => theme.colors.black};
    font-style: normal;
    font-weight: bold;
    font-size: max(16px, 1.11112vw);
    line-height: 125%;
  }

  /* We keep all the text in one line using ellipsis */
  .live-title, .live-location, .live-time {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  &:hover {
    .container-image {
      opacity: 0.8;
    }
  }
`
