import React from 'react'
import styled from 'styled-components'

import { ContactUs } from '../../components/ContactUs/ContactUs'
import { useMenuState } from '../../providers/menuProvider'

interface LayoutProps {
  className?: string
  style?: React.CSSProperties
}
export const Layout: React.FC<LayoutProps> = ({
  children,
  className,
  style,
}) => {
  const menuState = useMenuState()

  return (
    <>
      <Main className={className} style={style}>
        {children}
      </Main>

      {menuState.contact && <ContactUs />}
    </>
  )
}

const Main = styled.main`
  height: 100%;
  width: 100%;
`
