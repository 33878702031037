import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import config from '../../../config'

import { User } from '../auth'

interface ReturnUser {
  token: string
  user: User
}

export const useWhoAmI = (): ReturnUser | undefined => {
  const router = useRouter()
  const [user, setUser] = useState<ReturnUser>()

  useEffect(() => {
    const callAPI = async () => {
      await fetch('/api/whoami')
        .then((res) => {
          if (!res.ok) {
            throw new Error('')
          }
          else {
            return res.json()
          }
        })
        .then((data) => {
          setUser(data)

          if(data.user.id && process.env.NODE_ENV !== 'development') {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              userId: data.user.id,
            })
          }
        })
        .catch(() => {
          // TODO: 
          config.LOGIN_TYPE !== 'NONE'  && router.push('/')
        })
    }

    callAPI()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return user
}
