import {
  AnimatePresence,
  motion,
  useAnimation,
  Variants,
} from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Link from 'next/link'
import { Box } from './components/Box'
import BoxBackground from './components/BoxBackground'
import BoxLabel, { LabelFontSize } from './components/BoxLabel'
import { mediaMax } from '../../constants/breakpoints'
import { useMenuDispatch, useMenuState } from '../../providers/menuProvider'
import { Event } from '../../types/viewModels'
import 'swiper/css'
import 'swiper/css/pagination'
import config from '../../../config'
import RedTriangles from '../Svg/RedTriangles'
import { LiveIndicator } from '../../common/LiveIndicator'
import { AvatarImage } from '../../common/AvatarImage'

type BoxBase = {
  gridArea: string
  order: number
  label: string
  background: string
  backgroundScaleFrom?: 'top' | 'right' | 'bottom' | 'left'
  labelFontVariant: LabelFontSize
}

export type BoxProps = BoxBase & (
  {
    subMenu: Array<BoxProps>
    href?: null | undefined
  } | {
    href: string
    subMenu?: null | undefined
  }
)

/**
 * Animation constants
 */
const ANIMATION_DURATION_BASE = 0.125
const ANIMATION_DURATION_INCREASE = 0.001
const EASING = {
  easeInOutQuad: [0.45, 0, 0.55, 1],
}

/**
 * Framer-motion variants
 */
type VariantsLabel = { order: number }
const variantsLabel: Variants = {
  visible: ({ order }: VariantsLabel) => ({
    y: '0%',
    opacity: 1,
    transition: {
      ease: EASING.easeInOutQuad,
      duration: 0,
    },
  }),
  hidden: {
    y: '25%',
    opacity: 0,
    transition: {
      ease: EASING.easeInOutQuad,
      duration: 0.2,
    },
  },
}

type VariantsBackground = {
  order: number
  reverseOrder: number
  scale: 'x' | 'y'
}
const variantsBackground: Variants = {
  visible: ({ order }: VariantsBackground) => ({
    scaleX: 1,
    scaleY: 1,
    transition: {
      ease: EASING.easeInOutQuad, // also known as easeInOutQuad
      duration: ANIMATION_DURATION_BASE + order * ANIMATION_DURATION_INCREASE,
      delay: (order - 1) * (ANIMATION_DURATION_BASE + (order - 1) * ANIMATION_DURATION_INCREASE),
    },
  }),
  hidden: ({ scale, reverseOrder }: VariantsBackground) => ({
    scaleX: scale === 'x' ? 0 : 1,
    scaleY: scale === 'y' ? 0 : 1,
    transition: {
      when: 'afterChildren',
      ease: EASING.easeInOutQuad, // easeInOutQuad
      duration: ANIMATION_DURATION_BASE + reverseOrder * ANIMATION_DURATION_INCREASE,
      delay:
        (reverseOrder - 1) * (ANIMATION_DURATION_BASE + (reverseOrder - 1) * ANIMATION_DURATION_INCREASE),
    },
  }),
}

interface LiveItemProps {
  image?: string
  timeInterval: string
  title: string
  location: string
}
const LiveItem = ({ image, timeInterval, title, location }: LiveItemProps) => {
  
  const href = `${config.BASIC_ROUTES.live}/${location}`
  
  return(
    <Link href={href} passHref>
      <LiveItemWrapper>
        {/* <LiveItemImage src={`${config.LISA_IMAGE_BASE}${image}`} /> */}
        <StyledAvatarImage 
          image={image ? (`${config.LISA_IMAGE_BASE}${image}`) : undefined}
          style={{borderRadius: '0px'}}
          imageFallback={config.FALLBACK_PERSON_IMAGE}
          height={632}
          width={632}
          alt="speaker image"
        />
        <LiveItemInfo>
          <LiveItemTime>{timeInterval}</LiveItemTime>
          <LiveItemTitle><div dangerouslySetInnerHTML={{__html: title}} /></LiveItemTitle>
        </LiveItemInfo>
      </LiveItemWrapper>
    </Link>
  )
}

const StyledAvatarImage = styled(AvatarImage)`
height: max(4.5138888vw,65px);
width: max(4.5138888vw,65px);
  object-fit: cover;
  margin-right: 10px;

`

const LiveItemWrapper = styled.a`
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  padding: 0 20px 30px 20px;
`

const LiveItemImage = styled.img`
  width: 65px;
  height: 65px;
  object-fit: cover;
  margin-right: 10px;
`

const LiveItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const LiveItemTime = styled.span`
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-size: 14px;
  line-height: 19px;
  color: #303030;
  display:block;
`

const LiveItemTitle = styled.div`
  p{
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    font-size: 16px;
    line-height: 20px;
    color: #303030;
    font-weight: 700;
  }
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`


export type MenuViewState = 'open' | 'open-sub' | 'close' |  'close-sub'
interface MenuProps {
  // show?: boolean
  menu: Array<BoxProps>
  liveEvents: Array<Event>
}
export const Menu: React.FC<MenuProps> = ({
  // show,
  menu,
  liveEvents,
}: MenuProps) => {
  // TODO: All this menu logic should be a level higher.
  // This component is too smart and too involved. No time fuck it.
  const menuDispatch = useMenuDispatch()
  const menuState = useMenuState()
  const [subMenu, setSubMenu] = useState<Array<BoxProps> | null>(null)
  
  // We need the highest order so we can get a 1:1 revert animation
  const highestOrderMenu = Math.max(...menu.map((x) => x.order)) + 1
  let highestOrderSubMenu = 0
  if (subMenu) {
    highestOrderSubMenu = Math.max(...subMenu.map((x) => x.order)) + 1
  }

  const controlsMenu = useAnimation()
  // const controlsSubMenu = useAnimation()

  const handleOpenSubMenu = (menu: Array<BoxProps>) => {
    setSubMenu(menu)
    if(menu) {
      menuDispatch({ type: 'MENU', value: 'back' })
    }
  }

  // When the menu is opend we set html overflow to true for better moble experience.
  useEffect(() => {
    const html = document.querySelector<HTMLDivElement>('html')
    if (html === null) { return }
    if (menuState.menu !== 'burger') { html.style.overflow = 'hidden' }
    else { html.style.overflow = '' }

    return () => { html.style.overflow = '' }
  }, [menuState.menu])


  useEffect(() => {
    if(menuState.menu === 'burger') {
      controlsMenu.start('hidden')
      setSubMenu(null)
    }
    if(menuState.menu === 'close') {
      controlsMenu.start('visible')
      setSubMenu(null)
    }
  }, [menuState.menu])

  const show = menuState.menu !== 'burger'

  return (
    <>
      <Container
        initial="hidden"
        animate={controlsMenu}
        style={{ pointerEvents: show ? 'auto' : 'none' }}
      >
        <MenuBackground
          variants={{
            visible: {
              opacity: 1,
              transition: {
                duration: 0.8,
                ease: EASING.easeInOutQuad,
              },
            },
            hidden: {
              opacity: 0,
              transition: {
                duration: 0.8,
                ease: EASING.easeInOutQuad,
              },
            },
          }}
        />
        
        <Box 
          key="liveitems"
          gridArea="1 / 1 / span 32 / span 16"
          className={`first-box mobile-only ${liveEvents.length > 0 ? '' : 'hidden'}`}
        >
          <BoxBackground
            background="#fff"
            variants={variantsBackground}
            order={0}
            reverseOrder={highestOrderMenu - 0}
            className="first-box-background"
            scaleFrom="left"
          >
            {liveEvents.length > 0 && (
              <>
                <RedTriangles style={{ position:'absolute', top:0, left:0 }}/>
                <LiveIndicatorWrapper>
                  <LiveIndicator 
                    style={{ height: '20px', width: '20px', position: 'relative', background:'rgba(240, 52, 52, 0.1)', borderRadius: '50%', marginRight: 5}}
                    redDotPercentage="40%"
                  />
                  <LiveIndicatorText>Í BEINNI</LiveIndicatorText>
                </LiveIndicatorWrapper>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={24}
                  modules={[Pagination]}
                  pagination
                  autoHeight
                >
                  {liveEvents.map((event : Event) => {
                    return <SwiperSlide 
                      key={event.id}
                    >
                      <LiveItem
                        title={event.title}
                        timeInterval={event.timeInterval}
                        image={event.speakers.length > 0 ? event.speakers[0].image[0] : undefined}
                        location={event.location.slugurl}
                      ></LiveItem>
                    </SwiperSlide>
                  })}
                </Swiper>
              </>
            )}
          </BoxBackground>
        </Box>
        
        {menu.map((item, index) => (
          <Box
            key={item.label}
            gridArea={item.gridArea}
            // className={index === 0 ? 'first-box' : undefined}
          >
            <BoxBackground
              variants={variantsBackground}
              order={item.order}
              reverseOrder={highestOrderMenu - item.order}
              scaleFrom={item.backgroundScaleFrom}
              background={item.background}
              // className={index === 0 ? 'first-box-background' : undefined}
            >
              <BoxLabel
                href={item.href}
                custom={item.order}
                variants={variantsLabel}
                fontVariant={item.labelFontVariant}
                onClick={() => {
                  item.subMenu ? handleOpenSubMenu(item.subMenu) : null
                }}
              >
                {item.label}
              </BoxLabel>
            </BoxBackground>
          </Box>
        ))}
      </Container>

      <AnimatePresence>
        {subMenu && (
          <Container
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{ pointerEvents: subMenu ? 'auto' : 'none' }}
          >
            {subMenu.map((item, index) => (
              <Box
                key={item.label}
                gridArea={item.gridArea}
                // className={index === 0 ? 'first-box' : undefined}
              >
                <BoxBackground
                  variants={variantsBackground}
                  order={item.order}
                  reverseOrder={highestOrderSubMenu - item.order}
                  scaleFrom={item.backgroundScaleFrom}
                  background={item.background}
                  // className={index === 0 ? 'first-box-background' : undefined}
                >
                  <BoxLabel
                    href={item.href}
                    custom={item.order}
                    variants={variantsLabel}
                    fontVariant={item.labelFontVariant}
                  >
                    {item.label}
                  </BoxLabel>
                </BoxBackground>
              </Box>
            ))}
          </Container>
        )}
      </AnimatePresence>
    </>
  )
}

const LiveIndicatorWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 20px;
  display: flex;

`

const LiveIndicatorText = styled.span`
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #303030;
`

const MenuBackground = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  /* background-color: rgb(102, 219, 209); */
`

const Container = styled(motion.div)`
  z-index: 200;
  display: grid;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(32, 3.125vw);
  grid-template-rows: repeat(32, 3.125vh);

  .swiper-pagination{
    z-index: 20;
  }

  .swiper-pagination-bullet{
    opacity: 1;
    background-color: transparent;
    border: 1px solid black;
  }

  .swiper-pagination-bullet-active{
    background-color: black;
  }

  @media ${mediaMax.tablet} {
    display: flex;
    flex-direction: column;

    .first-box {
      flex: 1.5;
    }

    .first-box-background {
      padding-top: 105px;
    }
  }
`
