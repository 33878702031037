// eslint-disable-next-line max-len
export const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

interface Errors {
  [key: string]: string
}

interface Validation {
  errors: Errors
  isValid: boolean
}
const RegisterValidate = (inputs: any): Validation => {
  const errors: any = {}
  const {
    name, company, email, job,
  } = inputs

  // Name validation.
  if (!name.value) {
    errors.name = '*Vantar að fylla inn nafn'
  }

  // Company validation.
  if (!company.value) {
    errors.company = '*Vantar að fylla inn nafn fyrirtækis'
  }

  if (!job.value) {
    errors.job = '*Vantar að fylla inn starfsheiti'
  }

  // Email validation
  if (!email.value) {
    errors.email = '*Vantar að fylla inn netfang'
  }
  else if (!regexEmail.test(String(email.value).toLowerCase())) {
    errors.email = '*Ógilt netfang'
  }

  const validation: Validation = {
    errors,
    isValid: Object.keys(errors).length === 0,
  }

  return validation
}

export default RegisterValidate
