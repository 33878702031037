import { useMemo } from 'react'
import { LISAEventResolved } from '../../types/lisa'
import { Event } from '../../types/viewModels'
import { isEventHidden, mapLISAEventsToViewModel } from '../cms'


type UseEvents = (eventsResolved: LISAEventResolved[]) => Event[]
export const useEvents: UseEvents = (eventsResolved) => {
  const result: Event[] = useMemo(() => {
    return mapLISAEventsToViewModel(eventsResolved)
      .filter((event) => !isEventHidden(event))
  }, [eventsResolved])

  return result
}