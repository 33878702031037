import Link from 'next/link'

import ButtonHover from '../../Buttons/Circle/ButtonHover'

export type ButtonCustomProps = {
  className?: string
  style?: React.CSSProperties
  icon?: React.ReactNode
  active?: boolean
  id?: string
  text: string
} & (
  | {
    href?: never
    onClick: () => void
  }
  | {
    href: string
    onClick?: never
  }
)

export const ButtonCustom: React.FC<ButtonCustomProps> = ({
  icon,
  text,
  active,
  id,
  onClick,
  href,
}) => {
  const baseProps = {
    icon,
    text,
    active,
    id,
  }

  if (href) {
    return (
      <Link href={href} passHref>
        <ButtonHover
          as="a"
          {...baseProps}
        />
      </Link>
    )
  }
  if (onClick) {
    return (
      <ButtonHover
        onClick={onClick}
        {...baseProps}
      />
    )
  }

  return null
}
