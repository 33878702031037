import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import { Language as SVGLanguage } from '../../Svg/Language'
import { ICE as SVGICE } from '../../Svg/Flags/ICE'
import { UK as SVGUK } from '../../Svg/Flags/UK'
import { ExpandableButton, ExpandButtonProps } from '../../Buttons/ExpandableButton'
import { useChangeLocale } from '../../../utils/hooks/useChangeLocale'
import { Locale } from '../../../constants/locale/locale'

type ExpandButtonPropsNoOrder = Omit<ExpandButtonProps, 'order'>

export const ExpandableButtonLocale: React.FC = () => {
  const router = useRouter()
  const { handleChangeLocale } = useChangeLocale()

  const expandButtons = useMemo<ExpandButtonProps[]>(() => {
    if(!router.locales) { return [] }
    
    const result:ExpandButtonPropsNoOrder[] = router.locales.map((l) => {
      if(l === Locale.is) {
        return {
          title: l,
          icon: <SVGICE width="100%" />,
          onClick: () => { handleChangeLocale(l) },
        }
      }
      if(l === Locale.en) {
        return {
          title: l,
          icon: <SVGUK width="100%" />,
          onClick: () => { handleChangeLocale(l) },
        }
      }
      return {
        title: l,
        icon: <SVGLanguage width="100%" />,
        onClick: () => { handleChangeLocale(l) },
      }
    })

    return result.map((r, index) => ({ ...r, order: index + 1 }))
  }, [handleChangeLocale, router.locales])

  return (
    <ExpandableButton 
      icon={<SVGLanguage width="100%" />}
      type="button"
      buttons={expandButtons}
    />
  )
}