import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'
import { mediaMax } from '../../constants/breakpoints'
import { Locale } from '../../constants/locale/locale'
import { useMenuDispatch, useMenuState } from '../../providers/menuProvider'
import { useChangeLocale } from '../../utils/hooks/useChangeLocale'
import { useWhoAmI } from '../../utils/hooks/useWhoAmI'
import Close from '../Svg/CloseIcon'
import SvgUser from '../Svg/User'


const UserMenuMobile: React.FC = () => {
  const { t } = useTranslation('common')
  const user = useWhoAmI()
  const menuState = useMenuState()
  const menuDispatch = useMenuDispatch()
  const router = useRouter()

  const { handleChangeLocale } = useChangeLocale()

  const handleCloseMenu = () => {
    menuDispatch({ type : 'USER_MENU_TOGGLE'})
  }

  return(
    <FullPageWrapper className={menuState.user ? 'active' : 'notactive'}>
      <CloseButton onClick={() => handleCloseMenu()}><Close width="29px" height="29px" /></CloseButton>
      <TopContent>
        <UserIconWrapper><SvgUser fill="#fff" /></UserIconWrapper>
        <UserName>{user?.user?.name}</UserName>
        <UserCompany>{user?.user?.company}</UserCompany>
      </TopContent>
      <BottomContent>
        <LanguageItem onClick={() => handleChangeLocale(Locale.is)}>
          <LanguageFlag src="/is-small.png" />
          <span>{t('languageIS')}</span>
          <LanguageTickBox active={router.locale === Locale.is} />
        </LanguageItem>
        <LanguageItem onClick={() => handleChangeLocale(Locale.en)}>
          <LanguageFlag src="/gb-small.png" />
          <span>{t('languageEN')}</span>
          <LanguageTickBox active={router.locale === Locale.en} />
        </LanguageItem>
        {/* <Link href="/favorites" passHref>
          <MenuLink>{t('myProgram')}</MenuLink>
        </Link> */}
        <Link href="/logout" passHref>
          <MenuLink>{t('logout')}</MenuLink>
        </Link>
      </BottomContent>
    </FullPageWrapper>
  )
}

export default UserMenuMobile

const CloseButton = styled.div`
  height: max(45px,3.125vw);
  min-width: max(45px,3.125vw);
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  right: 15px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const FullPageWrapper = styled.div`
  position:fixed;
  height:100%;
  width:100%;
  background-color:white;
  z-index:500;
  display:flex;
  flex-direction: column;
  display:none;

  @media ${mediaMax.tablet}{
    &.active{
      display:block;
    }

    &.notactive{
      display:none;
    }
  }
`

const TopContent = styled.div`
  min-height: 240px;
  height: 240px;
  background: linear-gradient(292.27deg, #AD0081 0%, #4F0077 100%);
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: flex-end;
  padding-bottom:35px;
  width: 100%;
`

const BottomContent = styled.div`
  height:100%;
`

const UserIconWrapper = styled.div`
  height: 60px;
  width: 60px;
  border: 2px solid white;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom:10px;
`

const UserName = styled.h2`
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  font-weight: 700;
`
  
const UserCompany = styled.h3`
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  font-weight: 400;
`

const LanguageItem = styled.div`
  height: 60px;
  line-height: 60px;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  font-size: 18px;
  font-weight: bold;
  padding-left:20px;
  border-top: 1px solid #E5E5E5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;

  >span{
    text-align:left;
    width: 100%;
  }
`

interface LanguageTickBoxProps {
  active: boolean
}
const LanguageTickBox = styled.div<LanguageTickBoxProps>`
  min-height: 24px;
  min-width: 24px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${({ active }) => {
    if(active){
      return `
        border: 2px solid #84A919;
        &:after{
          content:"";
          height: 12px;
          width: 12px;
          background: #84A919;
          border-radius: 50%;
        }
      `
    }else{
      return'border: 2px solid #E5E5E5;'
    }
  }}
`

const LanguageFlag = styled.img`
  margin-right:15px;
  min-width: 23px;
  min-height: 16px;
  object-fit: contain;
`

const MenuLink = styled.a`
  height: 60px;
  line-height: 60px;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  font-size: 18px;
  font-weight: bold;
  display:block;
  padding-left:20px;
  border-top: 1px solid #E5E5E5;
`