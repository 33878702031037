import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { useTranslation } from 'next-i18next'
import { ButtonLiveItem, ButtonLiveItemProps } from './ButtonLiveItem'
import { LiveIndicator } from '../../common/LiveIndicator'
import { mediaMax } from '../../constants/breakpoints'

export interface ButtonLiveItem extends ButtonLiveItemProps {
  id: string
}

export interface ButtonLiveProps {
  style?: React.CSSProperties
  className?: string
  active?: boolean
  liveList: ButtonLiveItem[]
}
// We don't want to show the button if there are no live events.
// We also want to unsubscribe to window resize event so we un-mount it instead if hiding it.
export const ButtonLive: React.FC<ButtonLiveProps> = (props) => props.liveList.length ? <Button {...props} /> : null

// Here we have the actual live button component.
const Button: React.FC<ButtonLiveProps> = ({
  style,
  className,
  liveList,
  active,
}) => {
  const { t } = useTranslation('common')
  // For the hover animation we need the button unexpanded width.
  const refButton = useRef<HTMLButtonElement>(null)
  const refExpandingBox = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const resize = () => {
      if (refButton.current === null) {
        return
      }
      if (refExpandingBox.current === null) {
        return
      }

      const width = refButton.current.clientWidth
      refExpandingBox.current.style.width = `${width}px`
    }

    resize() // Call it once.
    window.addEventListener('resize', resize) // Call it maybe more then once.

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <Container
      ref={refButton}
      style={style}
      className={classnames(className, { active })}
    >
      <ButtonContent>
        <StyledLiveIndicator />
        <Text>{t('live')}</Text>
      </ButtonContent>

      <HoverContent>
        <ExpandingBox ref={refExpandingBox} />

        <LiveList>
          {liveList.map((item) => (<ButtonLiveItem key={item.id} {...item} />))}
        </LiveList>
      </HoverContent>
    </Container>
  )
}

const LiveList = styled.div`
  padding: max(10px, 0.694444vw);
  padding-bottom: 0;
  opacity: 0;
  position: relative;
  z-index: 10;

  transition-duration: 200ms;
  transition-property: opacity;
`

const ButtonContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;

  transition-delay: 300ms;
  transition-duration: 100ms;
  transition-property: opacity;
`

const ExpandingBox = styled.div`
  position: absolute;
  height: max(45px, 3.125vw);
  border-radius: max(22.5px,1.5625vw);
  background-color: ${({ theme }) => theme.colors.white};
  top: 0;
  right: 0;
  transition-timing-function: cubic-bezier(.19,1,.22,1);
  transition-duration: 300ms;
  transition-delay: 200ms;
`

const HoverContent = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: max(500px, 34.72222vw);
  border-radius: max(22.5px,1.5625vw);
  pointer-events: none;

  /* transition-delay: 200ms;
  transition-duration: 200ms; */
`

const StyledLiveIndicator = styled(LiveIndicator)`
  height: max(45px, 3.125vw);
  width: max(45px, 3.125vw);
`

// This should be a button element.
const Container = styled.button`
    border: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    position: relative;

    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);

    border-radius: max(22.5px,1.5625vw);
    height: max(45px, 3.125vw);
    min-width: max(45px, 3.125vw);

    &:hover, &.active {
      ${ButtonContent} {
        transition-delay: 0s;
        opacity: 0;
      }

      ${HoverContent} {
        pointer-events: auto;

          ${ExpandingBox} {
            transition-delay: 0s;
            width: 100% !important;
            height: 100%;
          }
    
          ${LiveList} {
            opacity: 1;
            transition-delay: 200ms;
          }
      }

    }

    @media ${mediaMax.mobileL}{
      display:none;
    }
`

const Text = styled.span`
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-weight: bold;
  letter-spacing: 0;
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  color: ${({ theme }) => theme.colors.black};
  font-size: max(13px, 0.9vw);
  padding-right: max(20px, 1.2vw);
`
