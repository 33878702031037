import { appWithTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { Router, useRouter } from 'next/router'
import NProgress from 'nprogress' // nprogress module
import 'nprogress/nprogress.css'
import { useEffect, useMemo } from 'react'
import { Flip, toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { ThemeProvider } from 'styled-components'
import TagManager from 'react-gtm-module'
import ErrorPage from './404'
import config from '../../config'
import { CookieConcentProps } from '../components/CookieConsent/CookieConsent'
import { Menu } from '../components/Menu/Menu'
import UserMenuMobile from '../components/UserMenu/UserMenuMobile'
import { Locale } from '../constants/locale/locale'
import { MenuProvider, useMenuDispatch } from '../providers/menuProvider'
import { GlobalStyle, theme } from '../styles'
import '../styles/globals.css'
import { useLiveEvents } from '../utils/hooks/useLiveEvents'
import { useEvents } from '../utils/hooks/useEvents'
import { EnumRoles } from '../utils/auth'
import type { AppProps, NextWebVitalsMetric } from 'next/app'

const DynamicCookieConsent = dynamic<CookieConcentProps>(async () => {
  const mod = await import('../components/CookieConsent/CookieConsent')
  return mod.CookieConcent
}, { ssr: false })

const tagManagerArgs = {
  gtmId: 'GTM-5352RSH',
}

const Wrapper: React.FC<AppProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <MenuProvider>
        {/* <SocketCountProvider> */}
        <MyApp {...props} />
          
        {/* </SocketCountProvider> */}
      </MenuProvider>

      <ToastContainer
        position={toast.POSITION.BOTTOM_LEFT}
        autoClose={5000}
        hideProgressBar
        closeOnClick
        transition={Flip}
        closeButton={false}
      />
    </ThemeProvider>
  )
}

const MyApp: React.FC<AppProps> = appWithTranslation(({ Component, pageProps }) => {
  
  // const socketDispatch = useSocketCountDispatch()
  
  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
    
    // post the page view to the analytics server on page load -> 
    // this only happens once, there is another function for calling analytics on page changes
    // axios.post('/api/analytics/', {
    //   page: window.location.href,
    // }).catch((error) => {
    //   console.log('error', error)
    // })  
  }, [])

  const menuDispatch = useMenuDispatch()
  useEffect(() => {
    const handlePageChangeStart = () => {
      NProgress.start()
    }
    const handlePageChangeDone = (url : string) => {
      NProgress.done()
      menuDispatch({ type: 'MENU', value: 'burger' })
      menuDispatch({ type: 'USER_MENU', value: false })

      // const elem = document.getElementById('chat-widget-container')
      
      // if(elem && url.indexOf('/room')){
      //   elem.parentNode.removeChild(elem)
      // }
      // socketDispatch({ type: 'PAGECHANGE', new: url })
      
      // send a page change to the server to add to postgres database for analytics
      // axios.post('/api/analytics/', {
      //   page: window.location.href,
      // }).catch((error) => {
      //   console.log('error', error)
      // })
    }

    // Binding events.
    Router.events.on('routeChangeStart', handlePageChangeStart)
    Router.events.on('routeChangeComplete', handlePageChangeDone)
    Router.events.on('routeChangeError', handlePageChangeDone)

    return () => {
      Router.events.off('routeChangeStart', handlePageChangeStart)
      Router.events.off('routeChangeComplete', handlePageChangeDone)
      Router.events.off('routeChangeError', handlePageChangeDone)
    }
  }, [menuDispatch])

  const router = useRouter()
  const menuItems = useMemo(() => {
    // const defaultMenu = config.MENU_GENERAL
    // UNCOMMENT IF WE HAVE MORE THAN ONE ROLE
    // else if(pageProps.user.role === EnumRoles.AnotherRole) {
    //   return config.MENU_ANOTHERROLE
    // }
    // if(pageProps?.user?.role !== EnumRoles.General) { return defaultMenu }

    if (router.locale === Locale.en) {
      return config.MENU_GENERAL_EN
    }
    if (router.locale === Locale.is) {
      return config.MENU_GENERAL
    }
    return config.MENU_GENERAL
  }, [router.locale])

  //check if there is a live event
  const events = useEvents(pageProps.events)
  const liveEvents = useLiveEvents(events)


  if (pageProps.pageError) {
    const { error } = pageProps.pageError
    return <ErrorPage text={error.statusText} />
  }

  return (
    <>
      <DynamicCookieConsent />

      <ToastContainer
        position={toast.POSITION.BOTTOM_LEFT}
        autoClose={5000}
        hideProgressBar
        closeOnClick
        transition={Flip}
        closeButton={false}
      />
      
      <Menu menu={menuItems} liveEvents={liveEvents}/>
      <UserMenuMobile />

      <Component {...pageProps} />
    </>
  )
})

export function reportWebVitals(metric: NextWebVitalsMetric) {
  console.log(metric)
}

export default Wrapper
