/**
 * ! The key must be the same as the value
 * Helpful for consistent locale manipulation
 */
export const Locale = {
  en: 'en',
  is: 'is',
  default: 'en',
} as const

export type LocaleType = keyof Omit<(typeof Locale), 'default'>