import React from 'react'
import { SVG } from '../../types/svg'

const RedTriangles = ({ ...props } : SVG) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="179"
      height="104"
      fill="none"
      viewBox="0 0 179 104"
      { ...props }
    >
      <mask
        id="mask0"
        width="179"
        height="104"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M0 0H179V104H0z"></path>
      </mask>
      <g
        fill="#F03434"
        fillRule="evenodd"
        clipRule="evenodd"
        mask="url(#mask0)"
      >
        <path
          d="M180-203.561V-.466L0 103.558l180-307.119z"
          opacity="0.75"
        ></path>
        <path d="M0 103.558v-307.119h180L0 103.558z"></path>
      </g>
    </svg>
  )
}

export default RedTriangles
