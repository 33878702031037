/* eslint-disable no-multi-assign */
import axios from 'axios'
import { isWithinInterval, format } from 'date-fns'

import config from '../../../../config'
import { LISAEvent, LISAEventLocations, LISAEventResolved, LISAResponseDocument, LISASpeakers } from '../../../types/lisa'
import { Event } from '../../../types/viewModels'

type FormatEventDateToInterval = (arg: { dateTimeStart: string; dateTimeEnd: string }) => string
export const formatEventDateToInterval: FormatEventDateToInterval = ({ dateTimeStart, dateTimeEnd }) => {
  return `${format(new Date(dateTimeStart), 'HH:mm')} - ${format(new Date(dateTimeEnd), 'HH:mm')}`
}

type IsEventLive = (event: Event, now: Date) => boolean
export const isEventLive: IsEventLive = (
  { dateTimeStart, dateTimeEnd, id, title },
  now,
) => {
  if (!dateTimeStart || !dateTimeStart) {
    return false
  }
  try {
    return isWithinInterval(now, {
      // TODO: converting to date is expensive
      start: new Date(dateTimeStart),
      end: new Date(dateTimeEnd),
    })
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.error(`isEventLive - isWithinInterval - event: ${id} - ${title}`)
    return false
  }
}

type FilterEventsByIsLive = (events: Array<Event>, now?: Date) => Event[]
export const filterEventsByIsLive: FilterEventsByIsLive = (events, now = new Date()) => {
  return events ? events.filter((event) => isEventLive(event, now)) : []
}


type IsEventInLocation = (event: { location: LISAEventLocations }, location: string) => boolean
export const isEventInLocation: IsEventInLocation = ({ location: eventLocation }, location) => {
  if (eventLocation && eventLocation.slugurl) {
    return eventLocation.slugurl === location
  }
  return false
}


type SortEventsByTimeArg = { dateTimeStart: string }
type sortEventsByTimeComparator = (a: SortEventsByTimeArg, b: SortEventsByTimeArg) => number
export const sortEventsByTimeComparator: sortEventsByTimeComparator = (
  { dateTimeStart: a }, 
  { dateTimeStart: b }
) => {
  const a_date = new Date(a)
  const b_date = new Date(b)
  return a_date.getTime() - b_date.getTime()
}

type SortEventsByTime = <T extends SortEventsByTimeArg>(arg: T[]) => T[]
export const sortEventsByTime: SortEventsByTime = (events) => {
  return [...events].sort(sortEventsByTimeComparator)
}

type IsEventHidden = (event: Event) => boolean
export const isEventHidden: IsEventHidden = (event) => {
  return event.hidden === true
}

export type LISAResponseDocumentEvents = LISAResponseDocument<LISAEvent, unknown>
type FetchLISAEvents = () => Promise<LISAResponseDocumentEvents>
export const fetchLISAEvents: FetchLISAEvents = () => {
  if (config.LISA_API_URL === undefined) {
    throw new Error('config.LISA_API_URL is undefined')
  }
  if (process.env.LISA_API_KEY === undefined) {
    throw new Error('process.env.LISA_API_KEY is undefined')
  }

  const urlLisa = `${config.LISA_API_URL}/documents/Documents`
  const query = `/query?modelId=${config.LISA_modelId_speakers}&count=300`
  const url = `${urlLisa}${query}`

  return axios.get(url, { headers: { Authorization: `Bearer ${process.env.LISA_API_KEY}` } }).then((r) => {
    return r.data
  })
}

type ResolveLinkedDocumentsLISAEvent = (responseDocument: LISAResponseDocumentEvents) => LISAEventResolved[]

export const resolveLinkedDocumentsLISAEvent: ResolveLinkedDocumentsLISAEvent = ({ documents, linkedDocuments }) => {
  const resolveLinkedDocument: LISAEventResolved[] = documents && documents.map((document) => {
    const location: LISAEventLocations = !!document.fieldValues.location
      ? linkedDocuments[document.fieldValues.location[0]].fieldValues as LISAEventLocations
      : { displayName: '', slugurl: '' }

    const speakers: Array<LISASpeakers> = !!document.fieldValues.speakers ? document.fieldValues.speakers.map(speaker =>  {
      const linkedSpeaker: LISASpeakers = linkedDocuments[speaker].fieldValues as LISASpeakers
      return linkedSpeaker
    }) : []

    return {
      ...document.fieldValues,
      id: document.id,
      location,
      speakers: speakers,
    }
  })

  return resolveLinkedDocument
}

type MapLISAEventToViewModel = (eventResolved: LISAEventResolved) => Event
export const mapLISAEventToViewModel: MapLISAEventToViewModel = (event) => {
  const new_speakers = event.speakers.map(speaker => {
    let new_image: Array<string> = []
    if (speaker.image) {
      new_image = speaker.image.map((x) => x.assetId)
    }

    return {...speaker, image: new_image}
  })
  // titleSimple was not always required so it might be empty.
  // ita non-markup copy of title so we prune the title if titleSimple is empty.
  const titleSimple = !event.titleSimple 
    ? event.title.replace(/(<([^>]+)>)/gi, '')
    : event.titleSimple

  const timeInterval = formatEventDateToInterval(event)

  return {
    ...event,
    speakers: new_speakers,
    titleSimple,
    timeInterval,
  }
}

type MapLISAEventsToViewModel = (eventsResolved: LISAEventResolved[]) => Event[]
export const mapLISAEventsToViewModel: MapLISAEventsToViewModel = (eventsResolved) => {
  return eventsResolved ? eventsResolved.map(mapLISAEventToViewModel) : []
}

type FetchEventsForPage = () => Promise<LISAEventResolved[]>
export const fetchEventsForPage: FetchEventsForPage = async () => {
  const responseDocument = await fetchLISAEvents()
  if(!responseDocument.documents){
    throw Error('no response document')
  }
  const LISAEventResolved = resolveLinkedDocumentsLISAEvent(responseDocument)
  return LISAEventResolved
}
