import { useRouter } from 'next/router'
import { useMemo } from 'react'
import jsCookie from 'js-cookie'

export const useChangeLocale = () => {
  const router = useRouter()
  const handleChangeLocale = useMemo(() => (locale: string) => {
    const path = router.asPath
    const expires = 365 * 2 // cookie expires in two years
    jsCookie.set('NEXT_LOCALE', 'locale', { expires })
    router.push(path, path, { locale: locale })
  }, [router])

  return {
    handleChangeLocale,
  }
}

