
import React, { useEffect, createContext, ReactNode, useReducer } from 'react'

type MenuState = 'burger' | 'close' | 'back' 

type Action =
  | { type: 'MENU'; value: MenuState }
  | { type: 'USER_MENU'; value: boolean }
  | { type: 'USER_MENU_TOGGLE' }
  | { type: 'INFORMATION'; value: boolean }
  | { type: 'INFORMATION_TOGGLE' }
  | { type: 'CONTACT'; value: boolean }
  | { type: 'CONTACT_TOGGLE' }

type Dispatch = (action: Action) => void

type State = {
  menu: MenuState
  information: boolean
  contact: boolean
  user: boolean
}

const initialState: State = {
  menu: 'burger',
  information: false,
  contact: false,
  user: false,
}

const MenuStateContext = createContext<State | undefined>(undefined)
const MenuDispatchContext = createContext<Dispatch | undefined>(undefined)

const menuReducer = (state: State, action: Action): State => {
  switch (action.type) {
  case 'MENU':
    return {
      ...state,
      menu: action.value,
    }
  case 'USER_MENU_TOGGLE':
    return {
      ...state,
      user: !state.user,
    }
  case 'USER_MENU':
    return {
      ...state,
      user: action.value,
    }
  case 'INFORMATION_TOGGLE':
    return {
      ...state,
      information: !state.information,
    }
  case 'INFORMATION':
    return {
      ...state,
      information: action.value,
    }
  case 'INFORMATION_TOGGLE':
    return {
      ...state,
      information: !state.information,
    }
  case 'CONTACT':
    return {
      ...state,
      contact: action.value,
    }
  case 'CONTACT_TOGGLE':
    return {
      ...state,
      contact: !state.contact,
    }
  default:
    return state
  }
}

interface MenuProviderProps {
  children: ReactNode
}
const MenuProvider: React.FC<MenuProviderProps> = ({ children }: MenuProviderProps) => {
  // useEffect(() => {
  //   console.log('Menu mounted')

  //   return () => {
  //     console.log(';menu unmounted')
  //   }
  // }, [])

  const [state, dispatch] = useReducer(menuReducer, initialState)

  return (
    <MenuStateContext.Provider value={state}>
      <MenuDispatchContext.Provider value={dispatch}>
        <>{children}</>
      </MenuDispatchContext.Provider>
    </MenuStateContext.Provider>
  )
}

function useMenuState(): State {
  const context = React.useContext(MenuStateContext)
  if (context === undefined) {
    throw new Error('useMenuState must be used within a MenuProvider')
  }
  return context
}

function useMenuDispatch(): Dispatch {
  const context = React.useContext(MenuDispatchContext)
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a MenuProvider')
  }
  return context
}

export { useMenuState, useMenuDispatch, MenuProvider }
