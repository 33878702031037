/* eslint-disable max-len */
import React from 'react'
import { SVG } from '../../../types/svg'

// width="45"
// height="45"

export const ICE: React.FC<SVG> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
      {...props}
    >
      <rect width="45" height="45" fill="#fff" rx="22.5"></rect>
      <mask
        id="mask-ice"
        width="41"
        height="41"
        x="2"
        y="2"
        maskUnits="userSpaceOnUse"
      >
        <rect width="41" height="41" x="2" y="2" fill="#fff" rx="20.5"></rect>
      </mask>
      <g fillRule="evenodd" clipRule="evenodd" mask="url(#mask-ice)">
        <path fill="#003897" d="M-6 2h58.336v41H-6V2z"></path>
        <path
          fill="#fff"
          d="M-6 17.947h16.336V2h9.328v15.947h32.672v9.106H19.664V43h-9.328V27.053H-6v-9.106z"
        ></path>
        <path
          fill="#D72828"
          d="M-6 20.22h18.664V2h4.672v18.22h35v4.56h-35V43h-4.672V24.78H-6v-4.56z"
        ></path>
      </g>
    </svg>
  )
}
