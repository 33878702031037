/* eslint-disable react/require-default-props */
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import React from 'react'
import { useTranslation } from 'next-i18next'
import { Layout } from '../common/Layout/Layout'
import CustomError from '../components/CustomError/CustomError'
import { Header } from '../components/Header/Header'



export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...await serverSideTranslations(locale as string, ['common']),
    },
  }
}
interface ErrorPageProps {
  text?: string
}
const ErrorPage: React.FC<ErrorPageProps> = ({ text } : ErrorPageProps) => {
  const { t } = useTranslation('common')
  return (
    <Layout>
      <Head>
        <title>404</title>
      </Head>
      <Header />
      <CustomError text={text || `${t('pagenotfound')}`} />
    </Layout>
  )
}

export default ErrorPage
