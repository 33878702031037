import { motion, Variants } from 'framer-motion'
import styled from 'styled-components'

type ScaleFromOptions = { scale: 'x' | 'y'; transformOrigin: string }

interface BoxBackgroundProps {
  initial?: string
  animate?: string
  style?: React.CSSProperties
  className?: string
  scaleFrom?: 'top' | 'right' | 'bottom' | 'left'
  order: number
  reverseOrder: number
  background: string
  variants?: Variants
}
const BoxBackground: React.FC<BoxBackgroundProps> = ({
  style,
  className,
  scaleFrom,
  order,
  reverseOrder,
  background,
  variants,
  children,
}) => {
  // Default is scaleFrom === 'left'.
  let scaleFromOptions: ScaleFromOptions = { scale: 'x', transformOrigin: '0 50%' }
  if (scaleFrom === 'top') {
    scaleFromOptions = { scale: 'y', transformOrigin: '50% 0' }
  }
  else if (scaleFrom === 'right') {
    scaleFromOptions = { scale: 'x', transformOrigin: '100% 50%' }
  }
  else if (scaleFrom === 'bottom') {
    scaleFromOptions = { scale: 'y', transformOrigin: '50% 100%' }
  }

  return (
    <Background
      className={className}
      custom={{ order, scale: scaleFromOptions.scale, reverseOrder }}
      variants={variants}
      style={{
        background,
        transformOrigin: scaleFromOptions.transformOrigin,
        ...style,
      }}
    >
      {children}
    </Background>
  )
}

export default BoxBackground

export const Background = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: relative;
`
