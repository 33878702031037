/* eslint-disable array-callback-return */
import axios from 'axios'

import config from '../../../config'
import { theme } from '../../styles/theme'
import {
  LISAExpo, LISAExpoWindowInbound, LISAExpoWindowOutbound, LISAExpoWindowVideo, LISAOverviewRoomNames, LISAResponseDocument,
  UnionExpoWindows,
} from '../../types/lisa'
import {
  Expo, UnionWindows,
} from '../../types/viewModels'


export const lisaFetchExpo = async (): Promise<LISAResponseDocument<LISAExpo, UnionExpoWindows>> => {
  if (config.LISA_API_URL === undefined) {
    throw new Error('config.LISA_API_URL is undefined')
  }
  if (process.env.LISA_API_KEY === undefined) {
    throw new Error('process.env.LISA_API_KEY is undefined')
  }

  const urlLisa = `${config.LISA_API_URL}/documents/Documents`
  const query = `/query?modelId=${config.LISA_modelId_expo}&count=300`
  const url = `${urlLisa}${query}`

  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${process.env.LISA_API_KEY}`,
    },
  })

  return response.data
}

const validCompanyColor = (color = '') => {
  const regxIsHex = /[0-9A-Fa-f]{6}/g

  let result = color
  // If the user provides a color with no # we add it.
  if (!result.startsWith('#')) {
    result = `#${result}`
  }

  return regxIsHex.test(result) ? result : undefined
}

export const fetchExpo = async (): Promise<Array<Expo>> => {
  const response = await lisaFetchExpo()

  response.documents.sort((x, y) => {
    if (x.fieldValues.order && y.fieldValues.order && x.fieldValues.order > y.fieldValues.order) {
      return 1
    } if (x.fieldValues.order && y.fieldValues.order && x.fieldValues.order <= y.fieldValues.order) {
      return -1
    }
    return 0
  })

  return response.documents.map((document) => {
    const linkedWindows : Array<UnionWindows> = []
    let roomName = ''
    document.fieldValues.overviewRoomName && document.fieldValues.overviewRoomName.map((item) => {
      const linkedLisaItem = response.linkedDocuments[item].fieldValues as LISAOverviewRoomNames
      roomName = linkedLisaItem.slug
    })
    
    document.fieldValues.windows && document.fieldValues.windows.map((window) => {
      const linkedLisaItem = response.linkedDocuments[window]
      if (linkedLisaItem.modelId === config.LISA_modelId_WindowInbound) {
        linkedWindows.push({ ...linkedLisaItem.fieldValues as LISAExpoWindowInbound, type: 'Inbound' })
      }

      else if (linkedLisaItem.modelId === config.LISA_modelId_WindowOutbound) {
        linkedWindows.push({ ...linkedLisaItem.fieldValues as LISAExpoWindowOutbound, type: 'Outbound' })
      }

      else if (linkedLisaItem.modelId === config.LISA_modelId_WindowVideo) {
        linkedWindows.push({ ...linkedLisaItem.fieldValues as LISAExpoWindowVideo, type: 'Video' })
      }
    })

    const returnItem = {
      ...document.fieldValues,
      uid: document.uid ? document.uid : document.fieldValues.uid,
      documentId: document.id,
      brandColorhex: document.fieldValues.brandColorhex ? validCompanyColor(document.fieldValues.brandColorhex) : '#fff',
      windows: linkedWindows,
      overviewRoomName: roomName,
    }
    return returnItem
  })
}
