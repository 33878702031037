/* eslint-disable @next/next/no-img-element */
import styled from 'styled-components'
import Image from 'next/image'

interface AvatarImageProps {
  style?: React.CSSProperties
  className?: string
  image?: string
  imageFallback: string
  height: number
  width: number
  alt: string
  priority?: boolean,
  objectFit?: 'cover' | 'contain'
}
export const AvatarImage: React.FC<AvatarImageProps> = ({
  style,
  className,
  image,
  imageFallback,
  height,
  width,
  alt,
  objectFit = 'cover',
  priority,
}) => {
  return (
    <StyledAvatarImage 
      style={style} 
      className={className}
    >
      {image ? (
        <Image 
          src={image}
          alt={alt}
          height={height}
          width={width}
          objectFit={objectFit}
          objectPosition="center"
          priority={priority}
        />
      ) : (
        <img 
          className="image-fallback"
          alt={alt} 
          src={imageFallback} 
        />
      )}

    </StyledAvatarImage>
  )
}

const StyledAvatarImage = styled.div`
  background-color: #C4C4C4;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  width: max(3.125vw, 45px);
  height: max(3.125vw, 45px);

  >div{
    height: 100%;
  }

  img.image-fallback {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
`
