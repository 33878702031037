import React from 'react'
import { SVG } from '../../types/svg'

// width="45"
// height="45"

export const Burger: React.FC<SVG> = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45 45"
    {...props}
  >
    <rect width="45" height="45" fill="#fff" rx="22.5"></rect>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M32 17v-2H12v2h20zm0 4v2H12v-2h20zm0 6v2H12v-2h20z"
      clipRule="evenodd"
    ></path>
  </svg>
)

