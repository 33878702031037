import React from 'react'
import { useTranslation } from 'next-i18next'
import { ButtonCustom } from './ButtonCustom'
import { ButtonMenu } from './ButtonMenu'
import { useMenuDispatch, useMenuState } from '../../../providers/menuProvider'
import SVGEmail from '../../Svg/Email'
import SVGHouse from '../../Svg/House'


export type ButtonCommonProps = {
  variant: 'home' | 'contact' | 'menu'
}

export const ButtonCommon: React.FC<ButtonCommonProps> = ({ variant }) => {
  const { t } = useTranslation('common')
  const menuDispatch = useMenuDispatch()
  const menuState = useMenuState()

  const handleContactToggle = () => {
    menuDispatch({ type: 'CONTACT_TOGGLE' })
  }

  if (variant === 'menu') {
    return (
      <ButtonMenu />
    )
  }
  if (variant === 'home') {
    return (
      <ButtonCustom
        href="/"
        text={t('home')}
        icon={<SVGHouse width="45%" />}
        id="menu-btn-home"
      />
    )
  }
  if (variant === 'contact') {
    return (
      <ButtonCustom
        onClick={handleContactToggle}
        text={t('contact')}
        icon={<SVGEmail width="53.3334%" />}
        active={menuState.contact}
        id="menu-btn-contact"
      />
    )
  }

  return null
}
