import React, { useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { ButtonCustom } from './ButtonCustom'
import { useMenuDispatch, useMenuState } from '../../../providers/menuProvider'
import { Back as SVGBack } from '../../Svg/Back'
import { Burger as SVGBurger } from '../../Svg/Burger'
import { X as SVGX } from '../../Svg/X'


interface ButtonMenuProps {
  style?: React.CSSProperties
  className?: string
}
export const ButtonMenu: React.FC<ButtonMenuProps> = ({
  style,
  className,
}) => {
  const { t } = useTranslation('common')
  const menuDispatch = useMenuDispatch()
  const menuState = useMenuState()
  
  const handleClick = () => {
    if(menuState.menu === 'burger') {
      menuDispatch({ type: 'MENU', value: 'close' })
    }
    if(menuState.menu === 'close') {
      menuDispatch({ type: 'MENU', value: 'burger' })
    }
    if(menuState.menu === 'back') {
      menuDispatch({ type: 'MENU', value: 'close' })
    }
  }

  // For the user convenience close the menu on escape keypress.
  useEffect(() => {
    const closeMenuOnEsc = (event: KeyboardEvent) => {
      if(event.key === 'Escape') {
        if(menuState.menu === 'close') {
          menuDispatch({ type: 'MENU', value: 'burger' })
        }
        if(menuState.menu === 'back') {
          menuDispatch({ type: 'MENU', value: 'close' })
        }
      }
    }
    document.addEventListener('keydown', closeMenuOnEsc)
  
    return () => {
      document.removeEventListener('keydown', closeMenuOnEsc) 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuState.menu])

  // TODO: Translations
  let menuText = t('menu')
  let menuIcon = <SVGBurger width="100%" />
  if(menuState.menu === 'burger') {
    menuIcon = <SVGBurger width="100%" />
    menuText = t('menu')
  }
  if(menuState.menu === 'close') {
    menuIcon = <SVGX width="100%" />
    menuText = t('menuClose')
  }
  if(menuState.menu === 'back') {
    menuIcon = <SVGBack width="100%" />
    menuText = t('menuBack')
  }
  const menuActive = menuState.menu === 'close' || menuState.menu === 'back'

  return (
    <ButtonCustom
      style={style}
      className={className}
      onClick={handleClick}
      text={menuText}
      id="menu-btn-menu"
      icon={menuIcon}
      active={menuActive}
    />
  )
}