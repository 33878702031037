import { motion, Variants } from 'framer-motion'
import Link from 'next/link'
import React from 'react'
import styled, { css } from 'styled-components'

import { mediaMax } from '../../../constants/breakpoints'

export type LabelFontSize = 'xl' | 'l' | 'm' | 's'

interface BoxLabelProps {
  fontVariant: LabelFontSize
  custom: number
  variants: Variants
  href?: string | null | undefined
  onClick?: (event: React.MouseEvent) => void
}

const BoxLabel: React.FC<BoxLabelProps> = ({
  fontVariant,
  href,
  custom,
  variants,
  children,
  onClick,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    onClick && onClick(event)
  }

  const defaultProps = {
    onClick: handleClick,
    fontVariant,
    custom,
    variants,
  }

  // Button and link behave differently
  if (href) {
    return (
      <Link passHref href={href}>
        <StyledLink {...defaultProps}>
          {children}
        </StyledLink>
      </Link>
    )
  }

  return (
    <StyledButton {...defaultProps} type="button">
      {children}
    </StyledButton>
  )
}

export default BoxLabel


type NavItemProps = {
  fontVariant: LabelFontSize
}

const Base = css<NavItemProps>`
position: absolute;
  text-decoration: none;
  min-height: 25%;
  min-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;

  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-weight: normal;
  text-align: center;
  align-self: center;

  &:hover {
    text-decoration: underline;
  }

  ${({ fontVariant }) => {
    if (fontVariant === 'xl') {
      return 'font-size: max(30px,4.166666vw);'
    } if (fontVariant === 'l') {
      return 'font-size: 3.3333335vw;'
    } if (fontVariant === 'm') {
      return 'font-size: 2.5vw;'
    } if (fontVariant === 's') {
      return 'font-size: 1.666667vw;'
    }
  }}

  @media ${mediaMax.tablet} {
    height: 100%;
    width: 100%;
    font-size: max(30px,4.166666vw);
  }
`

const StyledLink = styled(motion.a)`${Base}`
const StyledButton = styled(motion.button)`${Base}`
