import { useState, useEffect } from 'react'
import { Event } from '../../types/viewModels'
import { filterEventsByIsLive } from '../cms'

const DEFAULT_INTERVAL_TIME = 1000 * 30 // One minute

type UseLiveEvents = (events: Event[], intervalTime?: number) => Event[]
export const useLiveEvents: UseLiveEvents = (events, intervalTime = DEFAULT_INTERVAL_TIME) => {
  const [liveEvents, setLiveEvents] = useState([])
  // TODO: Improvement would be to not update the state if there is not change.
  useEffect(() => {
    const interval = setInterval(() => {
      setLiveEvents(filterEventsByIsLive(events))
    }, intervalTime)
    setLiveEvents(filterEventsByIsLive(events))

    return () => {
      clearInterval(interval)
    }
  }, [events, intervalTime])
  
  return liveEvents
}