import { ReactNode } from 'react'
import styled from 'styled-components'
import { mediaMin } from '../../../constants/breakpoints'

interface BoxLinkProps {
  className?: string
  style?: React.CSSProperties
  children?: ReactNode
  gridArea: string
}

export const Box: React.FC<BoxLinkProps> = ({
  style,
  children,
  className,
  gridArea,
}) => (
  <StyledBoxLink
    className={className}
    style={{
      gridArea,
      ...style,
    }}
  >
    {children}
  </StyledBoxLink>
)

const StyledBoxLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: relative;
  flex: 1;

  &.hidden{
    display:none;
  }

  &.mobile-only{
    @media ${mediaMin.mobileL}{
      display:none;
    }
  }
`
