import React, { useState } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { ExpandButton } from '.'
import { ExpandButtonProps } from './ExpandButton'
export interface ExpandableButtonProps {
  style?: React.CSSProperties
  className?: string
  id?: string
  type: 'submit' | 'reset' | 'button'
  onClick?: (event: React.MouseEvent) => void
  icon: React.ReactNode
  active?: boolean
  buttons: Array<ExpandButtonProps>
}

export const ExpandableButton = React.forwardRef<HTMLButtonElement, ExpandableButtonProps>(({
  style,
  className,
  id,
  type,
  onClick,
  active: activeProp,
  icon,
  buttons,
}, ref) => {
  const [controlledActive, setControlledActive] = useState(false)
  const active = typeof activeProp === 'boolean' ? activeProp : controlledActive
  const handleClick = (event: React.MouseEvent) => {
    setControlledActive(!controlledActive)
    onClick && onClick(event)
  }
  return (
    <StyledExpandableButton
      className={classnames('social-button', className, { active })}
      style={style}
      id={id}
      type={type}
      ref={ref}
      onClick={handleClick}
    >
      <IconContainer>{icon}</IconContainer>
      <div className="container-expand">
        <div className={'container-expand-content'}>
          {buttons.map((props, index) => (
            <ExpandButton key={index} className="expand-button" {...props} />
          ))}
        </div>
      </div>
    </StyledExpandableButton>
  )
})
ExpandableButton.displayName = 'ExpandableButton'


const IconContainer = styled.div`
  height: 100%;
  width: 3.125vw;
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: white;
  border-radius: 50%;
`

const StyledExpandableButton = styled.button`
  position: relative;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({theme}) => theme.colors.white};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  height: max(45px, 3.125vw);
  width: max(45px, 3.125vw);

  .container-expand {
    position: absolute;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;
    height: 100%;
    left: max(22.5px, 1.5625vw);
    transition: none 0s ease 0s;

    .container-expand-content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      transform: translate3d(-100%, 0px, 0px);
      transition: transform 0.45s step-end 0s;

      border-radius: 0 1.73077em 1.73077em 0;
      padding: 0 max(22.5px, 1.5625vw);
    }

    .expand-button {
      transition: transform 0.45s cubic-bezier(0.65, 0, 0.076, 1) 0s;
    }
  }

  &:hover,
  &.active {
    .container-expand {
      pointer-events: auto;
      
      .container-expand-content {
        transform: translate3d(0px, 0px, 0px);
        transition: none 0s ease 0s;
      }
  
      .expand-button {
        transform: translate3d(0, 0, 0);
      }
    }
  }
`