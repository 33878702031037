/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import { rgba } from 'polished'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { lengthIndicator } from './utils'
import { mediaMax } from '../../constants/breakpoints'
import { useMenuDispatch } from '../../providers/menuProvider'
import { theme } from '../../styles/theme'
import { regexEmail } from '../../utils/validators/registerValidate'
import ButtonBase, { IconContainer } from '../Buttons/Circle/Button'
import DottedLoading from '../Loading/DottedLoading'
import CloseIcon from '../Svg/CloseIcon'


const NAME_MAX_LENGTH = 80
const MESSAGE_MAX_LENGTH = 480


type FormData = {
  name: string
  email: string
  message: string
}

enum ViewState {
  Default,
  Success,
  Error,
}

interface ContactFormProps {
  onClose?: () => void
  title?: string
  text?: string
}
export const ContactUs: React.FC<ContactFormProps> = ({
  onClose,
  title,
  text,
}) => {
  const { t } = useTranslation('common')

  const menuDispatch = useMenuDispatch()
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    else {
      menuDispatch({ type: 'CONTACT', value: false })
    }
  }

  useEffect(() => {
    const escFunction = (event: KeyboardEvent) => {
      if(event.key === 'Escape') {
        handleClose()
      }
    }
  
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [handleClose])

  const [viewState, setViewState] = useState(ViewState.Default)

  const {
    register,
    handleSubmit,
    watch,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<FormData>()
  const [watchName, watchMessage] = watch(['name', 'message'])

  const onSubmit = handleSubmit(async ({
    name,
    email,
    message,
  }) => {
    try {
      await axios.post('/api/contact-us/', {
        name,
        email,
        message,
        pathname: window.location.href,
      })
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response?.status)
        console.error(error.response?.data)
      }
      else {
        console.error(error)
      }
      return setViewState(ViewState.Error)
    }

    return setViewState(ViewState.Success)
  })

  return (
    <StyledBackground>
      <ClosingDiv onClick={handleClose} />
      <StyledModal>
        <StyledCloseButton onClick={handleClose}>
          <span className="sr-only">Close modal</span>
          <IconContainer>
            <CloseIcon width="53%" />
          </IconContainer>
        </StyledCloseButton>

        {/* <SideImage fluid={} /> */}

        <ModalContentWrapper>
          <ModalContent>
            {viewState === ViewState.Default && (
              <>
                <h2>{title || t('contactUs.contactHeader')}</h2>
                <p>{text || t('contactUs.contactText')}</p>
              </>
            )}
            {viewState === ViewState.Success && (
              <>
                <h2>{t('contactUs.contactSuccessTitle')}</h2>
                <p>{t('contactUs.contactSuccessText')}</p>
              </>
            )}
            {viewState === ViewState.Error && (
              <>
                <h2 style={{ color: theme.colors.error }}>{t('contactUs.contactErrorTitle')}</h2>
                <p>{t('contactUs.contactErrorText')}</p>
              </>
            )}

            <ModalForm className={viewState === ViewState.Success ? 'submitted' : ''}>
              <form
                name="contact-us"
                onSubmit={onSubmit}
                noValidate
              >
                <label htmlFor="name" className="sr-only">
                  {t('form.placeholderContactName')}
                </label>
                <input
                  style={{ marginBottom: 0 }}
                  type="text"
                  id="name"
                  placeholder={t('form.placeholderContactName')}
                  className={errors.name ? 'error' : ''}
                  {...register('name', {
                    maxLength: NAME_MAX_LENGTH,
                    required: true,
                  })}
                />
                <LengthIndicator
                  className={errors.name?.type === 'maxLength' ? 'error' : ''}
                >
                  {lengthIndicator(NAME_MAX_LENGTH, watchName)}
                </LengthIndicator>
                {errors.name?.type === 'required' && (
                  <ErrorMessage
                    style={{
                      marginTop: '-20px',
                    }}
                  >
                    {t('form.formRequiredName')}
                  </ErrorMessage>
                )}

                <label htmlFor="email" className="sr-only">
                  {t('form.placeholderEmail')}
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder={t('form.placeholderContactEmail')}
                  className={errors.email ? 'error' : ''}
                  {...register('email', {
                    required: true,
                    pattern: regexEmail,
                  })}
                />
                {errors.email?.type === 'required' && (
                  <ErrorMessage>{t('form.formRequiredEmail')}</ErrorMessage>
                )}
                {errors.email?.type === 'pattern' && (
                  <ErrorMessage>{t('form.formEmailInvalid')}</ErrorMessage>
                )}

                <label htmlFor="message" className="sr-only">
                  {t('form.placeholderMessage')}
                </label>
                <textarea
                  id="message"
                  placeholder={t('form.placeholderMessage')}
                  className={errors.message ? 'error' : ''}
                  {...register('message', {
                    required: true,
                    maxLength: MESSAGE_MAX_LENGTH,
                  })}
                />
                <LengthIndicator
                  className={errors.message?.type === 'maxLength' ? 'error' : ''}
                >
                  {lengthIndicator(MESSAGE_MAX_LENGTH, watchMessage)}
                </LengthIndicator>
                {errors.message?.type === 'required' && (
                  <ErrorMessage>{t('form.formRequiredMessage')}</ErrorMessage>
                )}

                <StyledButton
                  type="submit"
                  className={isSubmitting ? 'loading' : ''}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <LoadingWrapper>
                      <DottedLoading />
                    </LoadingWrapper>
                  ) : (
                    <ButtonText>{t('submit')}</ButtonText>
                  )}
                </StyledButton>
              </form>
            </ModalForm>
          </ModalContent>
        </ModalContentWrapper>
      </StyledModal>
    </StyledBackground>
  )
}


const ClosingDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`

const LengthIndicator = styled.div`
  text-align: right;
  padding-right: 22.5px;
  font-size: 12px;
  margin-bottom: 10px;
  color: rgb(118, 118, 118);

   &.error {
     color: ${({ theme }) => theme.colors.error};
   }
`

const ErrorMessage = styled.div`
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  margin-bottom: 10px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  color: #606060;
`
const LoadingWrapper = styled.div`
  display: flex;
`
const StyledCloseButton = styled(ButtonBase)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate3d(35%, -35%, 0);
`
const StyledModal = styled.div`
  width: 67vw;
  position: absolute;
  top: 15vh;
  height: 75vh;
  bottom: 10vh;
  background-color: white;
  display: flex;
  z-index: 30;

  @media ${mediaMax.tablet} {
    width: 80vw;
    height: auto;
  }
`

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  pointer-events: none;
  opacity: 0;
  z-index: 0;
  transition: z-index 0.5s step-end, opacity 0.5s cubic-bezier(0.8, 0, 0.07, 1);
  background: ${({ theme }) => `linear-gradient(135deg, ${rgba(theme.colors.primary.base, 0)} 0%,
   ${rgba(theme.colors.primary.base, 0.7)} 23%, ${rgba(theme.colors.primary.base, 1)} 100%) `};
  pointer-events: auto;
  transition: z-index 0.5s step-start, opacity 0.5s cubic-bezier(0.8, 0, 0.07, 1);
  z-index: 210;
  opacity: 1;
`

// const SideImage = styled.img`
//   height: 100%;
//   width: 26%;

//   img {
//     object-position: bottom right !important;
//   }

//   @media ${mediaMax.mobileL} {
//     display: none;
//   }
// `

const ModalContentWrapper = styled.div`
  width: 100%;
  overflow:auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom:20px;

  @media ${mediaMax.tablet} {
    width: 100%;
  }

  @media ${mediaMax.mobileS} {
    margin-top: 0%;
  }
`

const ModalContent = styled.div`
  width: 67%;
  margin-top: 12%;

  h2 {
    color: #303030;
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    font-size: max(30px, 2.0833vw);
    font-weight: bold;
    letter-spacing: 0;
    line-height: 38px;
    margin-bottom: 1.042vw;
  }

  p {
    color: #606060;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: max(16px, 0.947vw);
    letter-spacing: 0;
    line-height: 28px;
    max-width: max(200px, 30vw);
    margin-bottom: 35px;
  }
  @media ${mediaMax.tablet} {
    width: 80%;
    margin-top: 10px;
  }

  @media ${mediaMax.mobileS} {
    p {
      margin-bottom: 0;
    }
  }
`

const ModalForm = styled.div`
  &.submitted {
    pointer-events: none;
    opacity: 0;
  }

  input,
  textarea {
    width: 100%;
    max-width: 100%;
    height: 45px;
    border-radius: 22.5px;
    background-color: #ffffff;
    margin-bottom: 10px;
    border: 1px solid #e5e5e5;
    padding-left: 20px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    padding-top: 2px;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};

    &:focus {
      outline: none;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: #808080;
      font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #808080;
      font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }

    ::placeholder {
      color: #808080;
      font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }

    &.error {
      border: 2px solid ${({ theme }) => theme.colors.error};
    }
  }

  textarea {
    height: 100px;
    max-height: 300px;
    padding-top: 10px;
  }
`

const ButtonText = styled.span`
  display: block;

  &.hidden {
    display: none;
  }
`

const StyledButton = styled.button`
  height: max(36px, 2.5vw);
  width: 100%;
  border-radius: 22.5px;
  background: ${({ theme }) => `
    linear-gradient(90deg, ${rgba(theme.colors.primary.base, 1)} 45%, ${rgba(theme.colors.primary.base, 0.7)} 73%)
  `};
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-size: max(18px, 1vw);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &.loading {
    background: unset;
  }
`
