import styled from 'styled-components'

export const IconContainer = styled.div`
  height: 100%;
  width: 3.125vw;
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: white;
  border-radius: 50%;
`

const Button = styled.button`
  border: none;
  margin: 0;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0;

  background: #fff;
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  color: ${({ theme }) => theme.colors.black};

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);

  font-size: max(13px, 0.9vw);
  border-radius: 1.73077em;
  /* padding: 0 1.53em; */
  padding: 0;
  height: 3.125vw;
  width: 3.125vw;
  min-height: 45px;
  min-width: 45px;
  line-height: 1.385em;

  &:focus,
  &:active {
    outline: none;
  }
`

export default Button
