import { BoxProps } from './src/components/Menu/Menu'

const ROUTES = {
  live: '/live',
  program: '/program',
  event: '/event',
  showroom: '/showrooms',
  subroom: '/room',
  favorites: '/favorites',
}

// General menus:

// FOR A NEW MENU FOR A DIFFERENT ROLE, MAKE ANOTHER MENU LIKE THIS ONE
const menuGeneral: Array<BoxProps> = [
  // {
  //   label: 'Bein útsending',
  //   order: 1,
  //   background: '#33517A',
  //   gridArea: '1 / 1 / span 32 / span 10',
  //   backgroundScaleFrom: 'top',
  //   labelFontVariant: 'xl',
  //   href: `${ROUTES.live}/reykjavik`,
  // },
  {
    label: 'Dagskrá',
    order: 1,
    background: '#142E59',
    gridArea: '1 / 1 / span 32 / span 16',
    backgroundScaleFrom: 'top',
    labelFontVariant: 'xl',
    href:`${ROUTES.program}/reykjavik`,
  },
  {
    label: 'Sýningarbásar',
    order: 2,
    background: '#40577A',
    gridArea: '1 / 17 / span 32 / span 16',
    backgroundScaleFrom: 'top',
    labelFontVariant: 'xl',
    href: ROUTES.showroom,
  },
]

const menuGeneralEN: Array<BoxProps> = [
  // {
  //   label: 'Live broadcast',
  //   order: 1,
  //   background: '#33517A',
  //   gridArea: '1 / 1 / span 32 / span 10',
  //   backgroundScaleFrom: 'top',
  //   labelFontVariant: 'xl',
  //   href: `${ROUTES.live}/reykjavik`,
  // },
  {
    label: 'Programme', 
    order: 1,
    background: '#142E59',
    gridArea: '1 / 1 / span 32 / span 16',
    backgroundScaleFrom: 'top',
    labelFontVariant: 'xl',
    href:`${ROUTES.program}/reykjavik`,
  },
  {
    label: 'Showrooms',
    order: 2,
    background: '#40577A',
    gridArea: '1 / 17 / span 32 / span 16',
    backgroundScaleFrom: 'top',
    labelFontVariant: 'xl',
    href: ROUTES.showroom,
  },
]

/* eslint-disable */
export default {
  // Lisa
  LISA_modelId_speakers: '5c046b6f-0e47-4b08-8a31-08d96d67cf61',
  LISA_modelId_streams: 'd4889b17-28da-4439-5d17-08d9995ea340',
  LISA_modelId_frontPage: '91aa3af7-e26f-4468-1af6-08d8cde33116',
  LISA_modelId_fakelogin: '',
  LISA_modelId_registrations: '8ff5883b-8ed2-44ef-8a33-08d96d67cf61',
  LISA_modelId_loginLogs: '4cef5197-e399-45c4-8a34-08d96d67cf61',
  LISA_modelId_contact: 'e854d4f6-d1af-4ec3-8a35-08d96d67cf61',
  LISA_modelId_expo: '21ee57c9-76b3-4412-8a36-08d96d67cf61',
  LISA_modelId_WindowOutbound: '6991914f-366f-4adc-8a38-08d96d67cf61',
  LISA_modelId_WindowInbound: '6d590431-3c22-4274-8a39-08d96d67cf61',
  LISA_modelId_WindowVideo: '57a5f049-962a-4983-8a3a-08d96d67cf61',
  LISA_modelID_overviewRooms: '4773359c-a37d-4362-8a3b-08d96d67cf61',

  LISA_modelID_allLocations: 'f63a77c9-4015-47b4-8a3c-08d96d67cf61',
  LISA_modelId_workshoplink: '',
  LISA_modelId_workshopProgram: '',
  
  LISA_API_URL: 'https://hugverk.azurewebsites.net/api',
  LISA_IMAGE_BASE: 'https://hugverk.azurewebsites.net/library?itemid=',

  /* CONTACT INFORMATION */
  CONTACT_email_subject: 'Fyrirspurn frá IP and sustainability',
  CONTACT_email_sender_name: 'Hugverk',
  CONTACT_receiver: 'vidburdir@hugverk.is',
  CONTACT_sender_name: 'Hugverk',

  // Custom styles
  BACKGROUND_image_lobby: '/Forsidumyndir-bla-min.jpg',
  BACKGROUND_image_live: '/Landmannalaugar-min.jpg',
  BACKGROUND_image_event: '/Landmannalaugar-min.jpg',
  BACKGROUND_image_program: '/Landmannalaugar-min.jpg',
  MOBILE_IMAGE_SYNINGARRYMI: '/Landmannalaugar-min.jpg',

  EXPO_WALLPAPER: {
    Top: '/harpa-loft.png', // "/tileTop.jpg",
    TwilioTop: '/flekar_workshop-02.png', // "/tileTop.jpg",
    Inner: '/harpa-wall.jpg', // "/TileInner.png",
    Floor: '/harpa-floor.jpg', // "/TileFloor.png",
    LeftRight: '/harpa-wall.jpg', // "/TileLeftRight.png"
  },

  // LOGO: '/1366x768_Glow.png',
  LOGO: '/ipandsustainability.png',
  LOGO_EN: '/ipandsustainability.png',

  PROJECT_NAME_SEO: 'IP and sustainability',
  PROJECT_URL_SEO: 'ipandsustainability.advania.is',
  PROJECT_IMAGE_SEO: '/Hugverkastofan_seo.jpg',

  SPONSORS_BACKGROUND: '/Bakgrunnur_sponsor.png',

  FALLBACK_PERSON_IMAGE: '/sample_user.png',

  // Options are : FAKE, LISA, NONE
  LOGIN_TYPE: 'NONE',

  // ARE THERE MULTIPLE LINES OF PROGRAMMING OG LIVE STREAMING, IF TRUE, DISPLAYS NAME OF EACH LINE IN PROGRAM TITLE
  MUTLIPLE_PROGRAMS: false,
  // MENU -> IF WE HAVE MORE THAN ONE ROLE.
  // CREATE E.G MENU_ANOTHERROLE : menu_anotherrole
  // THIS THEN NEEDS TO BE CONNECTED IN _APP.TSX
  
  BASIC_ROUTES: ROUTES,

  USE_FAVORITES: false,
  USE_TWILIO: false,
  USE_SHOWROOMS:true,
  
  // After opening the conference:
  MENU_GENERAL: menuGeneral,
  MENU_GENERAL_EN: menuGeneralEN,
  
  // Before opening the conference:
  // MENU_GENERAL: menuProgramSub,
  // MENU_GENERAL_EN: menuProgramSubEN,
  // MENU_GENERAL_SE: menuProgramSubSE,

  LOBBY_MENU_PROGRAMS: [],
  LOBBY_MENU_PROGRAMS_EN: [],

  LOBBY_MENU_ROOMS: [
    // {
    //   link: `${ROUTES.live}/reykjavik`,
    //   title: 'Bein útsending',
    //   openInNewTab: false,
    //   authAllowed: ['general'],
    // },
    {
      link: `${ROUTES.program}/reykjavik`,
      title: 'Dagskrá',
      openInNewTab: false,
      authAllowed: ['general'],
    },
    {
      link: ROUTES.showroom,
      title: 'Sýningarrými',
      openInNewTab: false,
      authAllowed: ['general'],
    },
  ],

  LOBBY_MENU_ROOMS_EN: [
    // {
    //   link: `${ROUTES.live}/reykjavik`,
    //   title: 'Live broadcast',
    //   openInNewTab: false,
    //   authAllowed: ['general'],
    // },
    {
      link: `${ROUTES.program}/reykjavik`,
      title: 'Programme',
      openInNewTab: false,
      authAllowed: ['general'],
    },
    {
      link: ROUTES.showroom,
      title: 'Showroom',
      openInNewTab: false,
      authAllowed: ['general'],
    },
  ],
  // Options are : IS, EN, SE
  LANGUAGE: 'IS',
}
